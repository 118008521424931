// pages/_app.js
import { Poppins } from "next/font/google"; // Intégration des polices Google avec @next/font

// Charger les polices Poppins via next/font
const poppins = Poppins({
  subsets: ["latin"],
  weight: ["400", "500", "700"], // Poids nécessaires pour votre projet
  display: "swap", // Permet d'améliorer le chargement des polices
});

import "../src/index.css";
import "../src/style.css";
import "../src/gradients.css";
import "react-vertical-timeline-component/style.min.css";
import "../styles/globals.css";
import "../styles/pre-custom-style.css";
import "../styles/vendors/menu.css";

function MyApp({ Component, pageProps }) {
  return (
    <div className={poppins.className}>
      <main>
        <Component {...pageProps} />
      </main>
    </div>
  );
}

export default MyApp;
